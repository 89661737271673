import AppTitle from '@theme/components/atom/AppTitle'
import Modal from '@theme/components/utils/Modal'

export default {
  components: {
    AppTitle,
    Modal,
  },
  name: 'CompareModal',
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  methods: {
    open() {
      this.$refs.modal.show()
    },
  },
}
