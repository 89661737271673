import { render, staticRenderFns } from "./CompareModal.vue?vue&type=template&id=40b69ca3&"
import script from "./CompareModal.js?vue&type=script&lang=js&"
export * from "./CompareModal.js?vue&type=script&lang=js&"
import style0 from "./CompareModal.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CompareModal.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fbase%2Fcomponents%2Fproduct%2FCompareModal%2FCompareModal.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports